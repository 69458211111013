// extracted by mini-css-extract-plugin
export var boxes = "HomeJumbotronBoxes__boxes__nBM5z";
export var column = "HomeJumbotronBoxes__column__TCgJ8";
export var container = "HomeJumbotronBoxes__container__gS3SU";
export var flex = "HomeJumbotronBoxes__flex__aaOnQ";
export var flexColumn = "HomeJumbotronBoxes__flexColumn__Xpw2X";
export var gap1 = "HomeJumbotronBoxes__gap1__vIZ1w";
export var gap2 = "HomeJumbotronBoxes__gap2__z2NUG";
export var gap3 = "HomeJumbotronBoxes__gap3__QUE8x";
export var gap4 = "HomeJumbotronBoxes__gap4__hOhtA";
export var gap5 = "HomeJumbotronBoxes__gap5__kuE9O";
export var horizontalScroll = "HomeJumbotronBoxes__horizontalScroll__ZnBZO";
export var row = "HomeJumbotronBoxes__row__eoN60";