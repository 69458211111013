// extracted by mini-css-extract-plugin
export var column = "HomeJumbotronTitle__column__ZF9bt";
export var flex = "HomeJumbotronTitle__flex__T43Zp";
export var flexColumn = "HomeJumbotronTitle__flexColumn__KI1K2";
export var gap1 = "HomeJumbotronTitle__gap1__rXj5W";
export var gap2 = "HomeJumbotronTitle__gap2__NZUWP";
export var gap3 = "HomeJumbotronTitle__gap3__jEttK";
export var gap4 = "HomeJumbotronTitle__gap4__39SiA";
export var gap5 = "HomeJumbotronTitle__gap5__O1aHk";
export var row = "HomeJumbotronTitle__row__Vdj38";
export var title = "HomeJumbotronTitle__title__l29Ww";