// extracted by mini-css-extract-plugin
export var box = "ServiceSectionBox__box__SdF6o";
export var column = "ServiceSectionBox__column__LtpFe";
export var description = "ServiceSectionBox__description__Y1f0O";
export var flex = "ServiceSectionBox__flex__R2vUn";
export var flexColumn = "ServiceSectionBox__flexColumn__imTk8";
export var gap1 = "ServiceSectionBox__gap1__i3zY6";
export var gap2 = "ServiceSectionBox__gap2__WJsv3";
export var gap3 = "ServiceSectionBox__gap3__VzHaQ";
export var gap4 = "ServiceSectionBox__gap4__amyEO";
export var gap5 = "ServiceSectionBox__gap5__AcNwN";
export var gray = "ServiceSectionBox__gray__vtIPb";
export var row = "ServiceSectionBox__row__rGQrj";
export var title = "ServiceSectionBox__title__Aakg1";
export var white = "ServiceSectionBox__white__JLcBx";