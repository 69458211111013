// extracted by mini-css-extract-plugin
export var column = "HomeProviderIcons__column__VnVAQ";
export var flex = "HomeProviderIcons__flex__ajOtO";
export var flexColumn = "HomeProviderIcons__flexColumn__cVMDa";
export var gap1 = "HomeProviderIcons__gap1___1RrV";
export var gap2 = "HomeProviderIcons__gap2__e87uD";
export var gap3 = "HomeProviderIcons__gap3__JY_0f";
export var gap4 = "HomeProviderIcons__gap4__O6Z6H";
export var gap5 = "HomeProviderIcons__gap5__Bdb7m";
export var icons = "HomeProviderIcons__icons__osJrA";
export var row = "HomeProviderIcons__row__w9Mqj";