// extracted by mini-css-extract-plugin
export var blue = "ServiceSection__blue__pfZGP";
export var column = "ServiceSection__column__uPFFw";
export var contentColumn = "ServiceSection__contentColumn__PRwUf";
export var contentRow = "ServiceSection__contentRow__NcgcV";
export var flex = "ServiceSection__flex__R47Ek";
export var flexColumn = "ServiceSection__flexColumn__Yt5He";
export var gap1 = "ServiceSection__gap1__GCbxn";
export var gap2 = "ServiceSection__gap2__w282t";
export var gap3 = "ServiceSection__gap3__ocAEF";
export var gap4 = "ServiceSection__gap4__M2yfI";
export var gap5 = "ServiceSection__gap5__l8zJm";
export var gray = "ServiceSection__gray__DR7k1";
export var row = "ServiceSection__row__w7m6c";
export var serviceSection = "ServiceSection__serviceSection__DjPBp";
export var subtitle = "ServiceSection__subtitle__tIe6P";
export var title = "ServiceSection__title__rjm_W";
export var white = "ServiceSection__white__q1Wiu";