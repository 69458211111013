// extracted by mini-css-extract-plugin
export var allNews = "HomeBlogPostSection__allNews__Projz";
export var column = "HomeBlogPostSection__column__ga_nV";
export var container = "HomeBlogPostSection__container__IL0PL";
export var description = "HomeBlogPostSection__description__ClZbb";
export var flex = "HomeBlogPostSection__flex__KRk2A";
export var flexColumn = "HomeBlogPostSection__flexColumn__NEyS6";
export var gap1 = "HomeBlogPostSection__gap1__bpxaV";
export var gap2 = "HomeBlogPostSection__gap2__uKV0M";
export var gap3 = "HomeBlogPostSection__gap3__OXMfM";
export var gap4 = "HomeBlogPostSection__gap4__ZIC2n";
export var gap5 = "HomeBlogPostSection__gap5__UGQHu";
export var row = "HomeBlogPostSection__row__tebgd";
export var section = "HomeBlogPostSection__section__qmvXv";
export var title = "HomeBlogPostSection__title__qEY2q";