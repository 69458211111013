// extracted by mini-css-extract-plugin
export var column = "HomeTestimonialsSection__column__v37OF";
export var flex = "HomeTestimonialsSection__flex__NmDdh";
export var flexColumn = "HomeTestimonialsSection__flexColumn__IKl54";
export var gap1 = "HomeTestimonialsSection__gap1__KN8zn";
export var gap2 = "HomeTestimonialsSection__gap2__af5mm";
export var gap3 = "HomeTestimonialsSection__gap3__VQ8ZV";
export var gap4 = "HomeTestimonialsSection__gap4__vM_Kq";
export var gap5 = "HomeTestimonialsSection__gap5__qJcVi";
export var row = "HomeTestimonialsSection__row__Sv0Ie";
export var testimonials = "HomeTestimonialsSection__testimonials__K8Jzw";