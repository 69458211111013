// extracted by mini-css-extract-plugin
export var box = "ServiceSectionProviderBox__box__AsRKA";
export var column = "ServiceSectionProviderBox__column__Frlu3";
export var description = "ServiceSectionProviderBox__description__y0F4x";
export var flex = "ServiceSectionProviderBox__flex__hlbea";
export var flexColumn = "ServiceSectionProviderBox__flexColumn__vswEu";
export var gap1 = "ServiceSectionProviderBox__gap1__u5w7H";
export var gap2 = "ServiceSectionProviderBox__gap2__e4OzT";
export var gap3 = "ServiceSectionProviderBox__gap3__zugrR";
export var gap4 = "ServiceSectionProviderBox__gap4__w9vgs";
export var gap5 = "ServiceSectionProviderBox__gap5__WjhWl";
export var link = "ServiceSectionProviderBox__link__pV7Rt";
export var logo = "ServiceSectionProviderBox__logo__she31";
export var row = "ServiceSectionProviderBox__row__OEn9T";