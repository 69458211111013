// extracted by mini-css-extract-plugin
export var buttons = "HomeBareMetalSection__buttons___yjQ9";
export var column = "HomeBareMetalSection__column__REHNP";
export var divider = "HomeBareMetalSection__divider__Yr6Z8";
export var flex = "HomeBareMetalSection__flex__rM5dl";
export var flexColumn = "HomeBareMetalSection__flexColumn__gGT0p";
export var gap1 = "HomeBareMetalSection__gap1__sOoDL";
export var gap2 = "HomeBareMetalSection__gap2__l90dO";
export var gap3 = "HomeBareMetalSection__gap3__VWsf9";
export var gap4 = "HomeBareMetalSection__gap4__evqI0";
export var gap5 = "HomeBareMetalSection__gap5__w8rvF";
export var providerBoxes = "HomeBareMetalSection__providerBoxes__j1lW9";
export var providers = "HomeBareMetalSection__providers__ekAVd";
export var providersTitle = "HomeBareMetalSection__providersTitle__HIzrp";
export var row = "HomeBareMetalSection__row__u3kX8";