// extracted by mini-css-extract-plugin
export var backgroundImage = "HomeJumbotron__backgroundImage__HHg8x";
export var column = "HomeJumbotron__column__d5g7n";
export var flex = "HomeJumbotron__flex__uAFW7";
export var flexColumn = "HomeJumbotron__flexColumn__UMooE";
export var gap1 = "HomeJumbotron__gap1__pGRTJ";
export var gap2 = "HomeJumbotron__gap2__j9Jdy";
export var gap3 = "HomeJumbotron__gap3__Ko5vS";
export var gap4 = "HomeJumbotron__gap4__p42OI";
export var gap5 = "HomeJumbotron__gap5__EG_yT";
export var heading = "HomeJumbotron__heading__BdEn3";
export var jumbotron = "HomeJumbotron__jumbotron__Ol8OS";
export var row = "HomeJumbotron__row__zAFBS";
export var wrapper = "HomeJumbotron__wrapper__MzA9A";