// extracted by mini-css-extract-plugin
export var box = "HomeJumbotronBox__box__zfUlV";
export var column = "HomeJumbotronBox__column__vMCpl";
export var flex = "HomeJumbotronBox__flex__tOJd9";
export var flexColumn = "HomeJumbotronBox__flexColumn__ZXY3k";
export var gap1 = "HomeJumbotronBox__gap1__w_vFo";
export var gap2 = "HomeJumbotronBox__gap2__qYACN";
export var gap3 = "HomeJumbotronBox__gap3__Kq4Lo";
export var gap4 = "HomeJumbotronBox__gap4__RRHBQ";
export var gap5 = "HomeJumbotronBox__gap5__PuAGH";
export var icon = "HomeJumbotronBox__icon__dwf0R";
export var message = "HomeJumbotronBox__message__OwKKa";
export var row = "HomeJumbotronBox__row__YRYID";
export var title = "HomeJumbotronBox__title__r0yVV";