// extracted by mini-css-extract-plugin
export var column = "HomeTopProvidersSection__column__Evet9";
export var flex = "HomeTopProvidersSection__flex__LU0La";
export var flexColumn = "HomeTopProvidersSection__flexColumn__rP_SN";
export var gap1 = "HomeTopProvidersSection__gap1__vm1gq";
export var gap2 = "HomeTopProvidersSection__gap2__ujYcQ";
export var gap3 = "HomeTopProvidersSection__gap3__euv3i";
export var gap4 = "HomeTopProvidersSection__gap4__zsG4a";
export var gap5 = "HomeTopProvidersSection__gap5__FyEk7";
export var providerLink = "HomeTopProvidersSection__providerLink__hUY6H";
export var row = "HomeTopProvidersSection__row__XvWMK";
export var topProviders = "HomeTopProvidersSection__topProviders__hHM5K";
export var topProvidersSection = "HomeTopProvidersSection__topProvidersSection__A8l4q";